<template>
  <div>
    管理员-异常信息
    <ResultLook :configInfo="configInfo" />
  </div>
</template>

<script>
import ResultLook from "@/components/index/ResultLook.vue";
export default {
  data() {
    return {
      configInfo: [
        {
          label: "请上传经营者身份证",
          variableName: "",
          placeholder: "",
          require: true,
          style: "font-size: 11px;",
          box: true,
          enter: true,
          width: "",
          maxlength: 0,
          type: "word_remark",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "身份证（正面）",
          variableName: "",
          placeholder: "请输入性别",
          require: true,
          enter: false,
          style: "",
          box: false,
          width: "50%",
          maxlength: 0,
          type: "sfz_font",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "身份证（反面）",
          variableName: "",
          placeholder: "请输入性别",
          require: true,
          enter: false,
          style: "",
          box: false,
          width: "50%",
          maxlength: 0,
          type: "sfz_back",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "经营者在办理过程中官方部门将进行身份验证",
          variableName: "",
          placeholder: "",
          require: false,
          style: "font-size: 11px;color: red;padding:10px 0;",
          box: false,
          enter: true,
          width: "",
          maxlength: 0,
          type: "word_remark",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "姓名", // label标签
          variableName: "", // 变量名
          placeholder: "请输入姓名", // input框内容描述
          require: true, //是否必传
          style: "", // 文字备注样式
          box: false, // 创建时间是否创建新盒子来放置
          maxlength: 10, // 内容最大长度
          type: "input", // 类型
          width: "",
          enter: true,
          data_check: [], // 数据格式校验
          cascade: "", // 联级目标变量
          selectData: [], //选择器的值
        },
        {
          label: "性别",
          variableName: "",
          placeholder: "请输入性别",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 5,
          type: "input",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "民族",
          variableName: "",
          placeholder: "请输入民族",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 5,
          type: "input",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "身份证号",
          variableName: "",
          placeholder: "请输入身份证号",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 18,
          type: "input",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "身份证住址",
          variableName: "",
          placeholder: "请输入身份证住址",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 18,
          type: "input",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "文化程度",
          variableName: "culture",
          placeholder: "请选择文化程度",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 18,
          type: "selector",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [
            {
              value: "高中",
              label: "高中",
            },
            {
              value: "大学",
              label: "大学",
            },
          ], //选择器的值
        },
        {
          label: "手机号",
          variableName: "",
          placeholder: "请输入手机号",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 11,
          type: "number",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "经营项目",
          variableName: "operate_obj",
          placeholder: "请选择经营项目",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 11,
          type: "selector",
          width: "",
          data_check: [],
          cascade: "organizational",
          selectData: [
            {
              value: "软件技术",
              label: "软件技术",
              children: [
                {
                  value: "服务中心",
                  label: "服务中心",
                },
              ],
            },
            {
              value: "日用百货",
              label: "日用百货",
              children: [
                {
                  value: "店",
                  label: "店",
                },
              ],
            },
          ], //选择器的值
        },
        {
          label: "组织形式",
          variableName: "organizational",
          placeholder: "选择组织形式",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 11,
          type: "selector",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "签名",
          variableName: "",
          placeholder: "",
          require: true,
          enter: true,
          style: "",
          box: false,
          maxlength: 0,
          type: "sign",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        // {
        //     label: '四级认证截图',
        //     variableName: '',
        //     placeholder: '',
        //     require: true,
        //     enter: true,
        //     style: '',
        //     box: false,
        //     maxlength: 0,
        //     type: 'img',
        //     width: '',
        //     data_check: [],
        //     cascade: '',
        //     selectData: [],    //选择器的值
        // },
        // {
        //     label: '海易办注册截图',
        //     variableName: '',
        //     placeholder: '',
        //     require: true,
        //     enter: true,
        //     style: '',
        //     box: false,
        //     maxlength: 0,
        //     type: 'img',
        //     width: '',
        //     data_check: [],
        //     cascade: '',
        //     selectData: [],    //选择器的值
        // },
        // {
        //     label: '注册密码一定要统一设置为a123456.',
        //     variableName: '',
        //     placeholder: '',
        //     require: false,
        //     enter: true,
        //     style: 'font-size: 11px;color:red;text-align:center;',
        //     box: false,
        //     maxlength: 0,
        //     type: 'word_remark',
        //     width: '',
        //     data_check: [],
        //     cascade: '',
        //     selectData: [],    //选择器的值
        // },
        {
          label: "营业执照收件信息",
          variableName: "",
          placeholder: "",
          require: false,
          enter: true,
          style:
            "font-size: 11px;border-left:3px solid #1e9fff;padding-left:5px;",
          box: true,
          maxlength: 0,
          type: "word_remark",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "请填写正确的地址，付款后暂不支持修改地址",
          variableName: "",
          placeholder: "",
          require: false,
          enter: true,
          style:
            "font-size: 11px;border: 1px solid orange;color: orange;padding:5px 0 5px 10px;width:260px;",
          box: false,
          maxlength: 0,
          type: "word_remark",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "收件人",
          variableName: "",
          placeholder: "请输入收件人",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 11,
          type: "input",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "收件人手机号",
          variableName: "",
          placeholder: "请输入收件人手机号",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 11,
          type: "input",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "收件地址",
          variableName: "",
          placeholder: "请选择收件地址",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 11,
          type: "selector",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
        {
          label: "详细地址",
          variableName: "",
          placeholder: "请输入详细地址",
          require: true,
          style: "",
          box: false,
          enter: true,
          maxlength: 11,
          type: "input",
          width: "",
          data_check: [],
          cascade: "",
          selectData: [], //选择器的值
        },
      ],
    };
  },
  components: {
    ResultLook,
  },
};
</script>

<style lang="less" scoped></style>
