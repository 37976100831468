<template>
  <div class="H5Box">
    <div class="title">
      <span>{{ "<" }} 返回 </span>
          <span class="title-main">设立登记</span>
    </div>
    <div class="FormBox">
      <div class="box" v-for="boxItem in boxData" :key="boxItem.index">
        <div :style="configItem.enter ? 'width: 100%;' : `width: ${configItem.width};`
          " v-show="index >= boxItem.index && index < boxItem.nextIndex" v-for="(configItem, index) in configInfo"
          :key="configItem.key">
          <!-- 输入框 -->
          <div v-if="['input', 'number'].includes(configItem.type)" class="inputClass">
            <div>
              <span class="require" :style="configItem.require ? 'color:red;' : 'color:blue;'">{{ configItem.require ?
                "*" : "" }}</span>
              <span>{{ configItem.label }}</span>
            </div>
            <div>
              <input :type="configItem.type == 'number' ? 'number' : 'text'" :placeholder="configItem.placeholder" />
            </div>
          </div>
          <!-- 选择器 -->
          <div v-if="['selector'].includes(configItem.type)" class="inputClass">
            <div>
              <span class="require" :style="configItem.require ? 'color:red;' : 'color:blue;'">{{ configItem.require ?
                "*" : "" }}</span>
              <span>{{ configItem.label }}</span>
            </div>
            <div>
              <select name="" id="" style="color: #777" :ref="configItem.variableName" @change="
                fun[`${configItem.variableName}Change`](
                  $event,
                  configItem.selectData
                )
                ">
                <option value="">{{ configItem.placeholder }}</option>
                <option :value="option.value" v-for="option in configItem.selectData" :key="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <!-- 文字备注 -->
          <div v-else-if="configItem.type == 'word_remark'" class="wordRemark">
            <div :style="configItem.style">
              <span v-if="configItem.require" style="color: red">*</span>
              {{ configItem.label }}
            </div>
          </div>
          <!-- 身份证图片(正面) -->
          <div v-else-if="configItem.type == 'sfz_font'" class="sfz">
            <div>
              <img src="../../assets/img/sfz_font.jpg" alt="" />
            </div>
          </div>
          <!-- 身份证图片(反面) -->
          <div v-else-if="configItem.type == 'sfz_back'" class="sfz">
            <div>
              <img src="../../assets/img/sfz_back.jpg" alt="" />
            </div>
          </div>
          <!-- 签名 -->
          <div v-else-if="configItem.type == 'sign'" class="sign">
            <div>
              <span class="require" :style="configItem.require ? 'color:red;' : 'color:blue;'">{{ configItem.require ?
                "*" : "" }}</span>
              <span style="font-weight: 600; font-size: 18px">{{
                configItem.label
              }}</span>
            </div>
            <div>
              <span class="goSign">去签名</span>
            </div>
          </div>
          <!-- 图片/截图 -->
          <div v-else-if="configItem.type == 'img'" class="sign">
            <div>
              <span class="require" :style="configItem.require ? 'color:red;' : 'color:blue;'">{{ configItem.require ?
                "*" : "" }}</span>
              <span style="font-weight: 600; font-size: 16px">{{
                configItem.label
              }}</span>
            </div>
            <div>
              <img style="vertical-align: middle" height="30" src="../../assets/img/tutorial.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="submitDiv">
        <button class="submitBtn">提交</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxData: [],
      fun: {},
    };
  },
  created() {
    let that = this;
    this.configInfo.forEach((item, index) => {
      item.key = 10000 + index;
      if (item.box || index == 0) {
        let nextIndex = 10000;
        let index0 = 0;
        for (let item0 of this.configInfo) {
          if (index0 > index && item0.box) {
            nextIndex = index0;
            break;
          }
          index0++;
        }
        this.boxData.push({ index, nextIndex });
      }

      if (item.type === "selector") {
        let key = `${item.variableName}Change`;
        that.fun[key] = (event, selectData) => {
          // console.log(event.srcElement.value);
        };
        // 选择器
        if (item.cascade !== "") {
          console.log(88);
          that.fun[key] = (event, selectData) => {
            let one = this.configInfo.find(
              (item0) => item0.variableName === item.cascade
            );
            let value = event.srcElement.value;
            if (value.trim() === "") {
              one.selectData = [];
              return;
            }
            let findOne = selectData.find((item0) => item0.value === value);
            one.selectData = findOne.children;
          };
        }
      }
    });
    console.log(999, this.boxData);
  },
  props: ["configInfo"],
};
</script>

<style scoped>
select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

select,
option,
input {
  cursor: pointer;
}

input[type="number"]::placeholder {
  transform: translate(14px, 0);
}

.sign {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
}

.sign .goSign {
  color: blue;
  cursor: pointer;
}

.submitDiv {
  height: 50px;
  background: #fff;
  line-height: 50px;
  text-align: center;
}

.submitBtn {
  border-radius: 15px;
  width: 90%;
  height: 33px;
  color: white;
  background-image: linear-gradient(4deg, #3aa6ff, #8ffafce1);
  border: none;
  outline: none;
}

.wordRemark {
  margin-bottom: 7px;
}

.sfz {
  text-align: center;
}

.sfz img {
  width: 95%;
}

select,
.inputClass input {
  border: none;
  text-align: right;
  outline: none;
  width: 150px;
  color: #444;
}

.inputClass {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.require {
  display: inline-block;
  width: 10px;
}

.box {
  margin: 7px;
  border-radius: 5px;
  min-height: 40px;
  background: #fff;
  padding: 8px 12px;
  color: #666;
  display: flex;
  flex-wrap: wrap;
}

span {
  letter-spacing: 2px;
}

.H5Box {
  width: 330px;
  height: 600px;
  border-radius: 30px;
  background: #f4f4f4;
  border: 1px solid #ddd;
  font-size: 14px;
  letter-spacing: 1px;
  overflow: hidden;
}

.FormBox {
  width: inherit;
  height: calc(100% - 35px);
  overflow: hidden scroll;
}

.FormBox::-webkit-scrollbar {
  display: none;
}

.title {
  background: white;
  color: #444;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
}

.title-main {
  margin-left: 22%;
}
</style>
