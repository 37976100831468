var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"H5Box"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s("<")+" 返回 ")]),_c('span',{staticClass:"title-main"},[_vm._v("设立登记")])]),_c('div',{staticClass:"FormBox"},[_vm._l((_vm.boxData),function(boxItem){return _c('div',{key:boxItem.index,staticClass:"box"},_vm._l((_vm.configInfo),function(configItem,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index >= boxItem.index && index < boxItem.nextIndex),expression:"index >= boxItem.index && index < boxItem.nextIndex"}],key:configItem.key,style:(configItem.enter ? 'width: 100%;' : `width: ${configItem.width};`)},[(['input', 'number'].includes(configItem.type))?_c('div',{staticClass:"inputClass"},[_c('div',[_c('span',{staticClass:"require",style:(configItem.require ? 'color:red;' : 'color:blue;')},[_vm._v(_vm._s(configItem.require ? "*" : ""))]),_c('span',[_vm._v(_vm._s(configItem.label))])]),_c('div',[_c('input',{attrs:{"type":configItem.type == 'number' ? 'number' : 'text',"placeholder":configItem.placeholder}})])]):_vm._e(),(['selector'].includes(configItem.type))?_c('div',{staticClass:"inputClass"},[_c('div',[_c('span',{staticClass:"require",style:(configItem.require ? 'color:red;' : 'color:blue;')},[_vm._v(_vm._s(configItem.require ? "*" : ""))]),_c('span',[_vm._v(_vm._s(configItem.label))])]),_c('div',[_c('select',{ref:configItem.variableName,refInFor:true,staticStyle:{"color":"#777"},attrs:{"name":"","id":""},on:{"change":function($event){_vm.fun[`${configItem.variableName}Change`](
                $event,
                configItem.selectData
              )}}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(configItem.placeholder))]),_vm._l((configItem.selectData),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])})],2)])]):(configItem.type == 'word_remark')?_c('div',{staticClass:"wordRemark"},[_c('div',{style:(configItem.style)},[(configItem.require)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(configItem.label)+" ")])]):(configItem.type == 'sfz_font')?_c('div',{staticClass:"sfz"},[_vm._m(0,true)]):(configItem.type == 'sfz_back')?_c('div',{staticClass:"sfz"},[_vm._m(1,true)]):(configItem.type == 'sign')?_c('div',{staticClass:"sign"},[_c('div',[_c('span',{staticClass:"require",style:(configItem.require ? 'color:red;' : 'color:blue;')},[_vm._v(_vm._s(configItem.require ? "*" : ""))]),_c('span',{staticStyle:{"font-weight":"600","font-size":"18px"}},[_vm._v(_vm._s(configItem.label))])]),_vm._m(2,true)]):(configItem.type == 'img')?_c('div',{staticClass:"sign"},[_c('div',[_c('span',{staticClass:"require",style:(configItem.require ? 'color:red;' : 'color:blue;')},[_vm._v(_vm._s(configItem.require ? "*" : ""))]),_c('span',{staticStyle:{"font-weight":"600","font-size":"16px"}},[_vm._v(_vm._s(configItem.label))])]),_vm._m(3,true)]):_vm._e()])}),0)}),_vm._m(4)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/img/sfz_font.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/img/sfz_back.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticClass:"goSign"},[_vm._v("去签名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"height":"30","src":require("../../assets/img/tutorial.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submitDiv"},[_c('button',{staticClass:"submitBtn"},[_vm._v("提交")])])
}]

export { render, staticRenderFns }